exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-detail-article-[id]-js": () => import("./../../../src/pages/detail-article/[id].js" /* webpackChunkName: "component---src-pages-detail-article-[id]-js" */),
  "component---src-pages-detail-news-js": () => import("./../../../src/pages/detail-news.js" /* webpackChunkName: "component---src-pages-detail-news-js" */),
  "component---src-pages-help-information-detail-[param]-js": () => import("./../../../src/pages/help-information/detail/[param].js" /* webpackChunkName: "component---src-pages-help-information-detail-[param]-js" */),
  "component---src-pages-help-information-detail-js": () => import("./../../../src/pages/help-information/detail.js" /* webpackChunkName: "component---src-pages-help-information-detail-js" */),
  "component---src-pages-help-information-js": () => import("./../../../src/pages/help-information.js" /* webpackChunkName: "component---src-pages-help-information-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-term-condition-detail-[flag]-js": () => import("./../../../src/pages/term-condition-detail/[flag].js" /* webpackChunkName: "component---src-pages-term-condition-detail-[flag]-js" */),
  "component---src-pages-term-condition-js": () => import("./../../../src/pages/term-condition.js" /* webpackChunkName: "component---src-pages-term-condition-js" */)
}

